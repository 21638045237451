import './index.scss'
import '../../assets/scripts/countNumber.js'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.nicescroll.min'

if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        $('body').niceScroll({
            mousescrollstep: 40,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#007b7b',
            cursorborder: '1px solid #007b7b',
            cursorwidth: '5px',
            cursoropacitymax: 0.6,
            smoothscroll: true,
            iframeautoresizeL: true,
        });
    }
}

function NiceScrollreset() {
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
}


$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();

    if (scroH > 10) {
        $('.index-page .header').addClass('white')
    }else{
        $('.index-page .header').removeClass('white')
    }

    let numScrollTop = $('.index-about .cont2').offset().top - viewH
    if (scroH > numScrollTop){
        numScrollabout()
    }

    // 集团品牌效果
    let brandTop = $('.index-brand .brand-cont').offset().top

    if (brandTop - viewH < scroH){
        let scaleX = (brandTop - scroH) / viewH

        $('.index-brand .brand-cont .left-shadow,.index-brand .brand-cont .right-shadow').css('transform', 'scaleX(' + scaleX +')')
        if (scaleX < 0.6){
            $('.index-brand .brand-cont .cont').css('opacity','1')
        }else{
            $('.index-brand .brand-cont .cont').css('opacity', '0')
            $('.index-brand .brand-cont .brand').css('box-shadow', '0 0 20px rgba(0,127,138,0)')
        }

        $('.index-brand .brand-cont .brand').hover(function () {
            $(this).css('box-shadow', '0 0 20px rgba(0,127,138,.4)')
            if (scaleX < 0.4) {
                $(this).css('box-shadow', '0 0 20px rgba(0,127,138,.4)')
            }else{
                $(this).css('box-shadow', '0 0 20px rgba(0,127,138,0)')
            }
        }, function () {
            $(this).css('box-shadow', '0 0 20px rgba(0,127,138,0)')
        })
    }
});

// 首页banner
var interleaveOffset = 0.5; //视差比值

var swiperOptions = {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    autoplay:false,
    // autoplay: {
    //     delay: 5000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    // },
    navigation: {
        nextEl: ".index-banner .swiper-button-next",
        prevEl: ".index-banner .swiper-button-prev"
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        type: "fraction",
    },
    on: {
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
};

var bannerSwiper = new Swiper(".index-banner .swiper-container", swiperOptions);

//首页关于尊龙凯时官网入口数字转动
function numScrollabout() {
    let num1 = $("#scrollNum1").html()
    let num2 = $("#scrollNum2").html()
    let num3 = $("#scrollNum3").html()
    let num4 = $("#scrollNum4").html()

    $("#scrollNum1").rollNum({
        deVal: num1
    });
    $("#scrollNum2").rollNum({
        deVal: num2
    });
    $("#scrollNum3").rollNum({
        deVal: num3
    });
    $("#scrollNum4").rollNum({
        deVal: num4
    });
}



// 全球结构关联
$('.index-map .left-text .map-list ul li').hover(function () {
    let index = $(this).index()
    $(this).addClass('active').siblings().removeClass('active')
    $('.index-map .right-map .map-list ul li').eq(index).addClass('active').siblings().removeClass('active')
})
$('.index-map .right-map .map-list ul li').hover(function () {
    let index = $(this).index()
    $(this).addClass('active').siblings().removeClass('active')
    $('.index-map .left-text .map-list ul li').eq(index).addClass('active').siblings().removeClass('active')
})

//新闻swiper
var newsSwiper = new Swiper(".news-swiper .mySwiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: ".news-swiper .swiper-button-next",
        prevEl: ".news-swiper .swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1280:{
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1440: {
            slidesPerView: 3,
            spaceBetween: 50,
        }
    },
});